// extracted by mini-css-extract-plugin
export var column = "CitiesDashboardOptions__column__ql2QK";
export var flex = "CitiesDashboardOptions__flex__YsHD8";
export var flexColumn = "CitiesDashboardOptions__flexColumn__BiCVP";
export var gap1 = "CitiesDashboardOptions__gap1__zV7op";
export var gap2 = "CitiesDashboardOptions__gap2__qOdjH";
export var gap3 = "CitiesDashboardOptions__gap3__M8MFC";
export var gap4 = "CitiesDashboardOptions__gap4__Bnrxu";
export var gap5 = "CitiesDashboardOptions__gap5__rkgrk";
export var popoverBody = "CitiesDashboardOptions__popoverBody__GzmbX";
export var popoverBodyLink = "CitiesDashboardOptions__popoverBodyLink__dO7OB";
export var popoverBodyLinkIcon = "CitiesDashboardOptions__popoverBodyLinkIcon__yp7sl";
export var row = "CitiesDashboardOptions__row__nkTHq";